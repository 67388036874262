<template>
    <TableLayout
        :module="module"
        ref="TableLayout"
        :form-initial="form_initial"
        :form-rules="formRules"
        :rights="rights"
        :table-columns="tableColumns"
        v-if="(user && user.rights) && loaded"
        initSortField="username"
        initSortOrder="asc"
        :filters="filters"
        addButtonName="Add User">

        <template v-slot:form_items="{formdata}">
            <div class="tl-form-row">
                <el-form-item label="Login" prop="username">
                    <el-input v-model="formdata.username" maxlength="100" minlength="5" />
                </el-form-item>
                <el-form-item label="Password" prop="password" v-if="!current || !current._id">
                    <el-input v-model="formdata.password" maxlength="50" minlength="5" />
                </el-form-item>
            </div>
            <el-form-item label="First Name" prop="firstname">
                <el-input v-model="formdata.firstname" maxlength="70" minlength="3" />
            </el-form-item>
            <el-form-item label="Last Name" prop="lastname">
                <el-input v-model="formdata.lastname" maxlength="160" minlength="3" />
            </el-form-item>
            <div class="tl-form-row">
                <el-form-item label="User Group" prop="group_id" style="min-width:70%;">
                    <el-select v-model="formdata.group_id">
                        <el-option
                            v-for="item in user_groups"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Active" prop="active">
                    <el-select v-model="formdata.active">
                        <el-option label="Yes" :value="true"></el-option>
                        <el-option label="No" :value="false"></el-option>
                    </el-select>
                </el-form-item>
            </div>
            
        </template>

    </TableLayout>
</template>

<script>
import { mapState } from 'vuex'
import TableLayout from '@/components/TableLayout'
import tab from '@/mixins/tab'

export default {
    name: 'users',
    mixins: [tab],
    components: { TableLayout },

    data() {
        return {
            module: 'users',
            loaded: false,
            form_initial: {
                active: true
            },
            filters: {
                username  : { value: undefined },
                firstname : { value: undefined },
                lastname  : { value: undefined },
                active    : { value: undefined, strict: true },
                group_id  : { value: undefined, strict: true },
            }
        }
    },

    computed: {
        ...mapState({
            user        : state => state.app.user,
            user_groups : state => state.userGroups.options,
        }),

        current(){
            return this.$store.state[this.module].current
        },

        tableColumns(){
            let columns = [
                {
                    field  : 'username',
                    title  : 'Login',
                    width  : 200,
                    fixed  : 'left',
                    params : {
                        filter : 'input',
                    },
                }, {
                    field    : 'firstname',
                    title    : 'First Name',
                    minWidth : 100,
                    params : {
                        filter : 'input',
                    },
                }, {
                    field    : 'lastname',
                    title    : 'Last Name',
                    minWidth : 100,
                    params : {
                        filter : 'input',
                    },
                }, {
                    field  : 'active',
                    title  : 'Active',
                    width  : 120,
                    align  : 'center',
                    params : {
                        filter : 'boolean',
                        editor : 'checkbox',
                    },
                },{
                    field    : 'group_id',
                    title    : 'User Group',
                    width    : 120,
                    params : {
                        filter  : 'select',
                        options : this.user_groups,
                        editor  : 'select',
                    },
                }
            ]
            return columns
        },

        formRules(){
            let rules = {
                username: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                    { min: 5, message: 'this field should have min 5 character', trigger: 'blur' },
                    { max: 100, message: 'this field should have max 100 characters', trigger: 'blur' },
                ],
                firstname: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                    { min: 3, message: 'this field should have min 3 characters', trigger: 'blur' },
                    { max: 70, message: 'this field should have max 70 characters', trigger: 'blur' },
                ],
                lastname: [
                    { min: 3, message: 'this field should have min 3 characters', trigger: 'blur' },
                    { max: 160, message: 'this field should have max 160 characters', trigger: 'blur' },
                ],
                group_id: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ]
            }

            if (!this.current || !this.current._id)
                rules['password'] = [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                    { min: 3, message: 'this field should have min 3 character', trigger: 'blur' },
                    { max: 50, message: 'this field should have max 50 characters', trigger: 'blur' },
                ];

            return rules
        },

        rights(){
            return {
                view : (this.user && this.user.rights) ? this.user.rights['get-users']   : false,
                edit : (this.user && this.user.rights) ? this.user.rights['edit-users']  : false,
                add  : (this.user && this.user.rights) ? this.user.rights['add-users']   : false,
                del  : (this.user && this.user.rights) ? this.user.rights['delete-users']: false,
            }
        },
    },

    mounted(){
        this.$store.dispatch('userGroups/getOptions').then(() => {
            this.loaded = true
        });
    },
}
</script>
